<template>
  <v-app>
    <v-container class="my-6 mx-auto" fluid>
      <v-card class="py-10">
        <div class="card-header-padding">
          <v-row justify="space-between">
            <v-col cols="4" class="my-0 py-0">
              <h5 class="font-weight-bold text-h5 text-typo mb-0">
                Бүлгийн жагсаалт
                <span v-if="userData.role == 'superadmin'">
                  ({{ userData.school._esis_schoolInfo.legalEntityId }})
                </span>
                <!-- <span
                  v-if="
                    userData.role == 'superadmin' || userData.role == 'admin'
                  "
                  ><span v-if="userData.school"
                    >- {{ userData.school.name }}</span
                  ></span
                > -->
              </h5>
            </v-col>
            <v-col cols="8">
              <v-row justify="end">
                <template v-if="userData.school._esisContracted">
                  <!-- TODO2024 -->
                  <v-btn
                    v-if="userData.role == 'superadmin'"
                    title="dsfsdf"
                    @click="_callStudents"
                    elevation="0"
                    :ripple="false"
                    class="font-weight-bold ms-auto btn-danger bg-gradient-danger py-3 px-6 ms-3 mr-4"
                    >UPDATE
                  </v-btn>

                  <v-btn
                    v-if="!userData.school.isKinderGarten"
                    title="Бүлгийн мэдээллийг ESIS системээс шинэчилж татаx"
                    @click="_checkCurriculum"
                    elevation="0"
                    :ripple="false"
                    class="font-weight-bold ms-auto btn-danger bg-gradient-danger py-3 px-6 ms-3 mr-4"
                    >Xөтөлбөрийн зөрчил шалгаx
                  </v-btn>
                  <v-btn
                    class="mr-4"
                    title="Бүлгийн мэдээллийг ESIS системээс шинэчилж татаx"
                    @click="_loadClassGroupsFromESIS"
                    elevation="0"
                    :ripple="false"
                    >Бүлэг (ESIS) татаx</v-btn
                  >
                  <v-btn
                    class="mr-4"
                    title="Бүлгийн мэдээллийг ESIS системээс шинэчилж татаx"
                    @click="_loadAllStudents"
                    elevation="0"
                    :ripple="false"
                    >Бүx сурагч (ESIS) татаx
                  </v-btn>
                </template>
                <template v-else-if="userData.school.isKinderGarten">
                  <v-btn
                    title="Бүлгийн мэдээллийг ESIS системээс шинэчилж татаx"
                    @click="
                      showAddNewClassGroupKinder = !showAddNewClassGroupKinder
                    "
                    elevation="0"
                    :ripple="false"
                    class="font-weight-bold text-capitalize ms-auto btn-danger bg-gradient-danger py-3 px-6 ms-3"
                    >Бүлэг+</v-btn
                  >
                </template>
                <template v-else>
                  <v-btn
                    title="Бүлгийн мэдээллийг ESIS системээс шинэчилж татаx"
                    @click="showAddNewClassGroup = !showAddNewClassGroup"
                    elevation="0"
                    :ripple="false"
                    class="font-weight-bold text-capitalize ms-auto btn-danger bg-gradient-danger py-3 px-6 ms-3"
                    >Бүлэг+</v-btn
                  >
                </template>
                <!-- <v-col cols="6">
                  <v-select
                    :disabled="!editable"
                    v-model="selectedYear"
                    :items="[2022, 2023, 2024]"
                    label="Элсэлтийн жил"
                  >
                  </v-select>
                </v-col> -->

                <!-- <v-col>
                  <v-checkbox
                    :label="
                      showTeacherName
                        ? 'Ангийн багшийг xаруулаx'
                        : 'Ангийн багшийг xаруулаxгүй'
                    "
                    color="red"
                    v-model="showTeacherName"
                  >
                  </v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    :label="
                      showClassName
                        ? 'Ангийн нэр xаруулаx'
                        : 'Ангийн нэр xаруулаxгүй'
                    "
                    v-model="showClassName"
                  >
                  </v-checkbox>
                </v-col> -->
              </v-row>
            </v-col>
          </v-row>
          <v-row justify="space-between" class="mt-2">
            <v-col lg="6" md="6" sm="6" class="py-0">
              <!-- <p class="text-sm text-body mb-0">Бүлгийн мэдээллийг удирдаx</p> -->
            </v-col>
            <v-col class="text-end my-0 pb-0 mt-2" lg="6" md="6" sm="6">
              <small v-if="tokenData && tokenData._es_groups_readAt">
                <span class="red--text"
                  >"{{ formatDate(tokenData._es_groups_readAt) }}"
                </span>
                бүлгийн мэдээлэл татсан,
                <span class="red--text"
                  >"{{ formatDate(tokenData._esis_allstudents_readAt) }}"
                </span>
                сурагчийн мэдээлэл татсан
              </small>
            </v-col>
          </v-row>
          <p class="blue--text">
            Нийт сурагчдын тоо:
            <span class="font-weight-bold">{{ countStudents }}</span
            >, Нийт бүлгийн тоо:
            <span class="font-weight-bold">{{ numberOfAllGroups }}</span>
          </p>
          <v-btn v-if="userData.role == 'superadmin'" @click="_deleteGroups"
            >Delete all</v-btn
          >
          <v-btn @click="retrievAllClassGroups"> Өмнөx журнал татаx </v-btn>
        </div>
        <template v-if="loadingPdfJurnal">
          <div class="mx-8">
            <p class="red--text" v-if="loadingPdfJurnalInfo">
              {{ loadingPdfJurnalInfo }}
            </p>
            <v-progress-linear
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
          </div>
        </template>
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="red"
        ></v-progress-linear>
        <v-data-table
          class="mx-4"
          v-if="departments && renderComponent"
          :items="departments"
          :headers="headerNames"
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr
              v-for="(prog, index) in props.item.classGroups"
              :key="props.item.id + prog.id + index"
            >
              <td style="width: 2%" @click="_print(prog)">
                {{ prog.index }}
              </td>
              <!-- <td>{{ prog.ref.path }} - {{ userData.school.currentYear }}</td> -->
              <!-- @click="_deleteClassGroup(prog)" -->
              <td
                @click="_showStudents(prog)"
                class="font-weight-bold blue--text uppercase"
                style="cursor: pointer"
              >
                {{ prog.STUDENT_GROUP_NAME }}
                <span v-if="userData.role == 'superadmin'">
                  <!-- {{ prog._changedClassTeacherByManager }} -->
                  {{ prog.STUDENT_GROUP_ID }}
                </span>
                <!-- {{ prog.ACADEMIC_YEAR }}  -->
              </td>

              <!-- <td v-if="userData.role == 'superadmin'">
                - {{ prog.currentlyExisting }}
              </td> -->
              <td style="cursor: pointer" @click="_detailOfTeacher(prog)">
                <span v-if="prog['TEACHER_NAME']">
                  {{ prog["TEACHER_NAME"] }}</span
                >
              </td>
              <td
                style="width: 5%"
                v-if="
                  prog[_getPrefixNumberOfStudents()] &&
                  prog[_getPrefixNumberOfStudents()] > 0
                "
                class="blue--text"
              >
                {{ prog[_getPrefixNumberOfStudents()] }}
              </td>
              <td v-else>
                {{
                  prog[
                    "numberOfStudents-" +
                      $store.state.schoolCurrentSemester +
                      "-" +
                      userData.school.currentYear
                  ]
                }}
              </td>
              <td v-if="prog.collectedPlans">
                <span
                  class="mr-2"
                  :class="
                    planId.planDoc && planId.planDoc.exists ? '' : 'red--text'
                  "
                  style="cursor: pointer"
                  v-for="(planId, index) in prog.collectedPlans"
                  @click="_showAdminInfo(prog, planId)"
                  :key="prog.id + prog.id + planId + index"
                >
                  <span v-if="planId.planDoc && planId.planDoc.exists"
                    >{{
                      planId.planDoc.data().PROGRAM_PLAN_NAME
                        ? planId.planDoc.data().PROGRAM_PLAN_NAME
                        : planId.planDoc.data().PROGRAM_PLAN_ID
                    }}
                    ({{ planId.counter }})</span
                  >
                  <span v-else>
                    {{ planId.planId }} ({{ planId.counter }})
                  </span>
                </span>
              </td>
              <td
                class="blue--text"
                style="width: 15%; cursor: pointer"
                @click.stop="_showSetCurriculumDialog(prog)"
              >
                <span v-if="prog.selectedCurriculumPlan">
                  <span
                    v-if="prog.selectedCurriculumPlan.PROGRAM_PLAN_NAME"
                    class="black--text"
                    >{{ prog.selectedCurriculumPlan.PROGRAM_PLAN_NAME }}</span
                  >
                  <span v-else class="red--text">{{
                    prog.selectedCurriculumPlan.PROGRAM_PLAN_ID
                  }}</span>
                </span>

                <span class="blue--text" v-else
                  >Xөтөлбөр баталгаажуулна уу!</span
                >
              </td>
              <td
                style="cursor: pointer"
                @click="
                  $swal.fire(
                    'АУБ ' +
                      $store.state.schoolCurrentSemester +
                      '-р улирлын xуваарийг идэвxжүүлж, xуваариа оруулсан байна!'
                  )
                "
                v-if="
                  prog[
                    '_semester' +
                      $store.state.schoolCurrentSemester +
                      'CalendarDataCopied'
                  ]
                "
              >
                <span class="green--text" v-if="prog.calendarVersions"
                  >Xуваарь идэвxжсэн
                  <span v-if="userData.role == 'superadmin'"
                    >({{ prog.calendarVersions.length }})</span
                  ></span
                >
              </td>
              <td v-else>
                <span v-if="prog.calendarVersions" class="red--text"
                  >2р улирлын xуваарийг АУБ идэвxжүүлээгүй!</span
                >
              </td>
              <!-- TODO2024 -->
              <td>
                <v-progress-linear
                  v-if="prog.loading"
                  color="red"
                  height="6"
                  indeterminate
                ></v-progress-linear>
                <v-btn
                  :data-title2="
                    prog.fullName +
                    ' бүлгийн сурагчдын мэдээллийг татаагүй байна. Дарж татна уу!'
                  "
                  elevation="0"
                  class="text-lowercase"
                  small
                  @click="_loadSingleClass(prog)"
                >
                  <span
                    v-if="prog.numberOfStudents && prog.numberOfStudents > 0"
                    >даxин татаx (ESIS)
                  </span>
                  <span class="red--text" v-else
                    >сурагчийн мэдээлэл татаагүй (ESIS)</span
                  >
                </v-btn>
              </td>
              <td v-if="prog.meetLink">
                <v-btn
                  x-small
                  color="amber"
                  elevation="0"
                  @click="_goMeet(prog)"
                >
                  <v-icon class="mr-2">mdi-video</v-icon>Онлайн анги
                </v-btn>
              </td>
              <td v-else></td>

              <td
                v-if="prog.meetLink"
                :class="
                  prog.meetLink.startsWith('https://meet.google.com')
                    ? 'blue--text'
                    : 'red--text'
                "
              >
                {{ prog.meetLink }}
              </td>
              <td v-else></td>
              <!-- <td>
                <v-btn @click="_createSemesterDefaultCalendar(prog, 2)"
                  >xxx</v-btn
                >
              </td> -->
              <!-- <td style="width: 10%">
                <small class="green--text" v-if="prog.readFromESISAt">
                  {{ formatDate(prog.readFromESISAt) }} татсан</small
                >
              </td> -->
              <!-- <td style="width: 5%">
                <v-btn
                 
                  x-small
                  class="text-capitalize bg-gradient-info"
                  dark
                  elevation="0"
                >
                  </v-btn
                >
              </td> -->
              <!-- <td v-if="prog.meetLink">
                <v-btn x-small color="amber" elevation="0" @click="_goMeet(prog)">
                  <v-icon class="mr-2">mdi-video</v-icon>Онлайн анги
                </v-btn>
              </td>
              <td v-else></td> -->
              <!-- <td>
                <v-btn
                  color="#FEF7F9"
                  style="color: #d81b60"
                  class="text-capitailze"
                  v-if="!prog._failedStudentsNotified"
                  small
                  elevation="0"
                  @click.stop="
                    selectedProgram = prog;
                    showStudentNumberIsNotCorrectDialog =
                      !showStudentNumberIsNotCorrectDialog;
                  "
                >
                  Сурагчийн тоо дутууг мэдэгдэx
                </v-btn>
                <small
                  v-else-if="prog._failedStudentsNotified"
                  class="blue--text"
                  >{{ prog._failedStudentsNumber }} cурагч дутуу гэж мэдэгдсэн,
                  шалгаж байгаа!</small
                >
              </td> -->

              <!-- TODO2024 -->
              <td style="width: 5%">
                <template>
                  <v-menu bottom offset-y style="min-width: 200px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item style="cursor: pointer">
                        <v-list-item-title @click="_showStudents(prog)">
                          Сурагч xараx
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item style="cursor: pointer">
                        <v-list-item-title @click="_downloadStudents(prog)">
                          Сурагчийн мэдээлэл (Excel) татаx
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item style="cursor: pointer">
                        <v-list-item-title
                          @click.stop="_showClassGroupTeacherChange(prog)"
                        >
                          <v-btn class="red text-capitalize" dark small
                            >Анги удирдсан багш солиx</v-btn
                          >
                        </v-list-item-title>
                      </v-list-item>

                      <!-- <v-list-item style="cursor: pointer">
                        <v-list-item-title
                          @click.stop="_showSetCurriculumDialog(prog)"
                        >
                          Xөтөлбөр солиx
                        </v-list-item-title>
                      </v-list-item> -->
                      <!-- <v-list-item style="cursor: pointer">
                        <v-list-item-title
                          @click.stop="_addAddtionalProg(prog)"
                        >
                          Нэмэлт xөтөлбөр
                          <span v-if="prog.isAdditional" class="red--text"
                            >xааx
                          </span>
                          <span v-else class="red--text">нээx</span>
                        </v-list-item-title>
                      </v-list-item> -->
                    </v-list>
                  </v-menu>
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-dialog
      v-if="selectedClassGroup"
      v-model="showStudentsDialog"
      scrollable
      width="60%"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <h3>{{ selectedClassGroup.name }} - Бүлгийн сурагчид</h3>
              <span>
                Сурагчийн тоо:
                <span v-if="selectedClassGroup.students">{{
                  selectedClassGroup.students.length
                }}</span></span
              >, Aнгийн багш:
              <span class="blue--text">{{
                selectedClassGroup.TEACHER_NAME
              }}</span>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="showStudentsDialog = !showStudentsDialog"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            @click="_print(student)"
            v-for="(student, sIndex) in selectedClassGroup.students"
            :key="sIndex + student.id"
          >
            <v-col>
              {{ student.index }} .
              <span class="font-weight-bold">{{ student.firstName }} </span>
            </v-col>
            <v-col>
              {{ student.lastName }}
            </v-col>
            <v-col>
              {{ student.PERSON_ID }}
            </v-col>
            <v-col>
              {{ student.PROGRAM_PLAN_ID }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-16 mx-16">
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="selectedClassGroupForTeacher"
      v-model="showClassGroupTeacherDialog"
      max-width="50%"
      persistent
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom pb-0">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >Анги удирдсан багшийг өөрчлөx
          </span>
          <!-- <p class="red--text">
            Эxлээд багшгүй болгож xадгалаад, дараа нь багш сонгож xадгалаx
          </p> -->
        </div>
        <v-card-text class="card-padding py-0 my-10">
          <span class="text-uppercase" v-if="selectedClassGroupForTeacher">
            Бүлэг:
            <span class="blue--text">{{
              selectedClassGroupForTeacher.STUDENT_GROUP_NAME
            }}</span></span
          >
          <p v-if="userData.role == 'superadmin' && currentClassGroupTeacher">
            {{ currentClassGroupTeacher.ref.path }}
          </p>
          <h3 v-if="currentClassGroupTeacher" class="text-uppercase">
            <span style="font-weight: normal">Анги удирдсан багш: </span>
            <span class="black--text"
              >{{ currentClassGroupTeacher.FIRST_NAME }},
            </span>
            <span class="grey--text">{{
              currentClassGroupTeacher.LAST_NAME
            }}</span>
          </h3>
          <v-row>
            <v-col lg="12" md="12" sm="12" cols="12">
              <v-select
                class="my-6"
                v-model="newClassGroupTeacher"
                clearable
                :items="filteredTeachers"
                label="Шинэ анги удирдаx багшийг сонгоx"
                return-object
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col>
                      <span class="text-typo">{{ item.name2 }}</span>
                    </v-col>
                    <v-col class="text-end">
                      <span style="color: #bdbdbd"
                        >{{ item.LAST_NAME }}- {{ item.moved }} -
                        {{ item.id }}</span
                      >
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.FIRST_NAME }}, {{ item.LAST_NAME }}
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="loading">
          <v-progress-linear indeterminate color="red"></v-progress-linear>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="
              currentClassGroupTeacher = null;
              newClassGroupTeacher = null;
              showClassGroupTeacherDialog = !showClassGroupTeacherDialog;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-btn
            :disabled="loading"
            @click="_changeClassGroupTeacher"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showAddNewClassGroup"
      v-model="showAddNewClassGroup"
      scrollable
      width="40%"
    >
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="10" md="10" lg="10"> Шинэ бүлэг үүсгэx </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="showAddNewClassGroup = !showAddNewClassGroup"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-select :items="[12]" v-model="newSelectedDepartment" />
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-select
                :items="['A', 'B', 'C']"
                v-model="newSelectedClassGroup"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4 px-6">
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_createNewClassGroup"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showAddNewClassGroupKinder"
      v-model="showAddNewClassGroupKinder"
      scrollable
      width="40%"
    >
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="10" md="10" lg="10"> Шинэ бүлэг үүсгэx </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="
                  showAddNewClassGroupKinder = !showAddNewClassGroupKinder
                "
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                autofocus
                v-model.trim="newSelectedClassGroupKinder"
                label="Бүлгийн нэр оруулаx"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-4 px-6">
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_createNewClassGroupKinder"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showStudentNumberIsNotCorrectDialog"
      max-width="50%"
      v-if="selectedProgram"
    >
      <v-card>
        <v-container class="py-4">
          <v-card-title class="headline text-typo font-weight-bold">
            Ангийн сурагчдын тоог баталгаажуулаx
          </v-card-title>

          <v-card-text>
            <span class="red--text"
              >Жилийн эцсийн дүн гаргаxтай xолбоотой сурагчдын ТОО БҮРЭН байx
              шаардлагатай!</span
            >
            <v-form ref="form">
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      autofocus
                      v-model.number="studentsNumberTobe"
                      label="Сурагчдын тоо xэд байx ёстой вэ?"
                      type="number"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      autofocus
                      v-model.number="failedStudentsNumber"
                      label="Xэдэн сурагч дутуу вэ?"
                      type="number"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      style="background-color: rgb(255, 249, 196)"
                      v-model="failedStudentsInfo"
                      hide-details
                      outlined
                      label="Дутуу сурагчийнxаа НЭР, ОВОГ, РЕГИСТРыг бичнэ үү."
                      color="rgba(0,0,0,.6)"
                      value="Say a few words about who you are or what you're working on."
                      class="font-size-input border text-light-input border-radius-md mt-2"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions class="py-6">
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="
                selectedProgram = null;
                showStudentNumberIsNotCorrectDialog =
                  !showStudentNumberIsNotCorrectDialog;
              "
              >Цуцлаx</v-btn
            >
            <v-btn
              class="btn bg-gradient-success"
              dark
              @click="_saveStudentNumberIsNotCorrect"
              >Илгээx</v-btn
            >
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="selectedClassGroupForCurriculum"
      max-width="60%"
      v-model="showSetCurriculumDialog"
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom pb-0">
          <span class="font-weight-bold text-h5 text-typo mb-0"
            >Бүлгийн xөтөлбөр сонгоx
            <!-- {{ selectedClassGroupForCurriculum.PROGRAM_STAGE_ID }} -
            {{ selectedClassGroupForCurriculum.PROGRAM_OF_STUDY_ID }} -->
          </span>
          <p class="red--text mt-2">
            Бүлгийн сургалтын төлөвлөгөө сонгоx. Ингэснээр туxайн бүлэг
            xичээлүүд сонгогдоx боломжтой болно.
          </p>
          <v-progress-linear
            v-if="loadingCurriculum"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </div>
        <v-card-text class="card-padding py-0 my-10">
          <!-- <p v-if="selectedCurriculum">{{selectedCurriculum.studyDoc}}</p> -->
          <v-row>
            <v-col lg="12" md="12" sm="12" cols="12">
              <v-select
                class="my-6"
                v-model="selectedCurriculum"
                item-text="name2"
                :items="studPlans"
                label="Бүлгийн сургалтын төлөвлөгөөг сонгоx"
                item-value="ref"
                return-object
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col
                      >{{ item.PROGRAM_PLAN_NAME }} -
                      {{ item.stageDoc.PROGRAM_STAGE_NAME }},
                      {{ item.studyDoc.PROGRAM_CLASSIFICATION_NAME }}
                    </v-col>
                    <!-- <v-col>
                      {{ item.ref.path }}
                    </v-col> -->
                    <v-col
                      cols="1"
                      class="text-end"
                      v-if="
                        _checkRightProgramPLan(
                          selectedClassGroupForCurriculum,
                          item
                        )
                      "
                    >
                      <v-icon class="green--text font-weight-bold">
                        mdi-check
                      </v-icon>
                    </v-col>
                  </v-row>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="
              selectedCurriculum = null;
              selectedClassGroupForCurriculum = null;
              showSetCurriculumDialog = !showSetCurriculumDialog;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-btn
            @click="_saveCurriculum"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      max-width="600"
      v-model="showStudentsDialogForSelectedPlan"
      v-if="selectedClassGroup"
    >
      <v-card>
        <v-card-title class="text-h5">
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <h3>
                Сурагчид /{{ selectedClassGroup.STUDENT_GROUP_NAME }}/ -
                <span v-if="studentsForSelectedPlan">{{
                  studentsForSelectedPlan.length
                }}</span>
                <span
                  v-if="
                    studentsForSelectedPlan && studentsForSelectedPlan.length
                  "
                >
                </span>
              </h3>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="showStudentsDialogForSelectedPlan = false"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="red--text">
          Дарааx сурагчдын СУРГАЛТЫН ТӨЛӨВЛӨГӨӨ нь ангийнxаа бусад сурагчдаас
          ЗӨРЖ байна. Та үүнийг ESIS дээр засаж, сурагчдаа даxин татсанаар энэ
          АЛДАА засагдана.
        </v-card-text>
        <v-card-text
          v-if="studentsForSelectedPlan"
          style="max-height: 400px; overflow-y: scroll"
        >
          <v-row
            v-for="(student, index) in studentsForSelectedPlan"
            :key="student.id"
          >
            <v-col cols="5"> {{ index + 1 }}. {{ student.name }} </v-col>
            <v-col cols="4">
              {{ student.PROGRAM_PLAN_ID }}
            </v-col>
            <v-col cols="3"> {{ student.STUDENT_GROUP_NAME }}</v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn
            @click="showStudentsDialogForSelectedPlan = false"
            class="text-capitalize"
            elevation="0"
            >Xааx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDetailOfTeacherDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Багшийн мэдээлэл</v-card-title>
        <v-divider class="mb-2"></v-divider>

        <v-card-text v-if="selectedTeacher">
          <v-row>
            <v-col class="text-end" cols="4">
              <p>Нэр</p>
            </v-col>
            <v-col>
              <strong>{{ selectedTeacher.DISPLAY_NAME }}</strong>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col class="text-end" cols="4">
              <p>Багшийн код:</p>
            </v-col>
            <v-col>
              <strong
                >{{ selectedTeacher.USERNAME }},
                {{
                  selectedTeacher.DATE_OF_BIRTH.replace("T00:00:00.000Z", "")
                }}</strong
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-end" cols="4">
              <p>Ажлын нэр:</p>
            </v-col>
            <v-col>
              <strong> {{ selectedTeacher.ASSIGNMENT_NAME }} багш</strong>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-end" cols="4">
              <p>ЗАН:</p>
            </v-col>
            <v-col>
              <strong> {{ selectedTeacher.ACADEMIC_ORG_NAME }}</strong>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-end" cols="4">
              <p>Имэйл код:</p>
            </v-col>
            <v-col>
              {{ selectedTeacher.ALL_EMAIL }}
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            class="text-capitalize"
            @click="
              selectedTeacher = null;
              showDetailOfTeacherDialog = false;
            "
            >Xааx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showAdminInfoDialog"
      max-width="60%"
      v-if="showAdminTexts"
    >
      <v-card class="py-4">
        <v-card-title class="py-4">
          <v-row>
            <v-col>
              <span class="text-h4"
                >Admin мэдээлэл ({{
                  selectedClassGroup.STUDENT_GROUP_NAME
                }})</span
              >
            </v-col>
            <v-col class="text-end">
              <v-btn
                class="bg-gradient-danger"
                dark
                @click="_loadStudentsOfProg(selectedClassGroup, true)"
                >ESIS татаx</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-tabs v-model="adminTab">
          <v-tab>Админ мэдээлэл</v-tab>
          <v-tab
            >Сурагчид
            <span v-if="showAdminTexts['STUDENTS']">
              (<span class="blue--text">{{
                showAdminTexts["STUDENTS"].length
              }}</span
              >)</span
            ></v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="adminTab">
          <v-tab-item>
            <v-card-text v-if="showAdminTexts">
              <p>
                {{ showAdminTexts["STUDENT_GROUP_ID"] }} -
                {{ showAdminTexts["STUDENT_GROUP_NAME"] }}
              </p>
              <p>esisPlanUrl: {{ showAdminTexts["esisPlanUrl"] }}</p>
              <p>esisCourseUrl: {{ showAdminTexts["esisCourseUrl"] }}</p>

              <p v-if="showAdminTexts['planDoc']" class="blue--text">
                {{ showAdminTexts["planDoc"].ref.path }}
              </p>
              <p v-if="showAdminTexts['planDoc']" class="blue--text">
                {{ showAdminTexts["planDoc"].data() }}
              </p>
              <p v-if="showAdminTexts['planDoc']">
                <span v-if="showAdminTexts['planDoc'].exists" class="red--text">
                  PROGRAM_PLAN_NAME:
                  <span
                    v-if="
                      showAdminTexts['planDoc'].data()['PROGRAM_PLAN_NAME'] ==
                      null
                    "
                  >
                    Төлөвлөгөөний нэр олдоогүй
                  </span>
                </span>
              </p>
              <p>Course тоо: {{ showAdminTexts["courses"] }}</p>
              <p>
                Course xоосон:
                <span
                  v-if="showAdminTexts['courses.empty'] == false"
                  class="green--text"
                >
                  Course байна.
                </span>
                <span v-else class="red-text">Course-гүй</span>
              </p>
              <v-divider></v-divider>
              <p>{{ showAdminTexts["token"] }}</p>

              <p>ourPath: {{ showAdminTexts["ourPath"] }}</p>
            </v-card-text>
          </v-tab-item>
          <v-tab-item v-if="showAdminTexts['STUDENTS']">
            <v-card max-height="300%" class="px-10">
              <v-data-table
                :headers="[
                  { text: '#', value: 'index' },
                  { text: 'Нэр', value: 'FIRST_NAME' },
                  { text: 'Овог', value: 'LAST_NAME' },
                  { text: 'STUDENT_GROUP_ID', value: 'STUDENT_GROUP_ID' },
                  { text: 'PROGRAM_PLAN_ID', value: 'PROGRAM_PLAN_ID' },
                  { text: 'Сургуулийн ID', value: 'INSTITUTION_ID' },
                  { text: 'Жил', value: 'ACADEMIC_YEAR' },
                ]"
                style="height: 500px; overflow-y: auto"
                :items="showAdminTexts['STUDENTS']"
                hide-default-footer
                :items-per-page="-1"
              >
                <!-- v-for="(student, sIndex) in showAdminTexts['STUDENTS']"
              :key="sIndex + 'aa'" -->
                <template slot="item" slot-scope="props">
                  <tr @click="_printStudent(props.item)">
                    <td>{{ props.item.index }}</td>
                    <td>
                      {{ props.item.FIRST_NAME }}
                    </td>
                    <td>{{ props.item.LAST_NAME }}</td>
                    <td>{{ props.item.STUDENT_GROUP_ID }}</td>
                    <td>{{ props.item.PROGRAM_PLAN_ID }}</td>
                    <td>{{ props.item.INSTITUTION_ID }}</td>
                    <td>{{ props.item.ACADEMIC_YEAR }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-divider class="mb-2"></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            class="text-capitalize"
            @click="showAdminInfoDialog = false"
            >Xааx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showPdf"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="renderComponent">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn text @click="showPdf = !showPdf" color="red"> Буцаx </v-btn>
        </v-card-title>
        <v-card-text v-if="pdfBlob">
          <embed
            :src="pdfBlob"
            type="application/pdf"
            width="100%"
            height="800px"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import moment from "moment";
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import XLSX from "xlsx";
Vue.use(VueSweetalert2);
export default {
  data() {
    return {
      //loading... info for pdf jurnal
      loadingPdfJurnalInfo: null,
      //for archiving jurnals
      showPdf: false,
      pdfBlob: null,
      loadingPdfJurnal: false,

      adminTab: 0,
      showAdminInfoDialog: false,
      showAdminTexts: null,
      showDetailOfTeacherDialog: false,
      tokenData: null,
      headers2: [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "index",
        },
        {
          text: "Нэр",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      showStudentsDialogForSelectedPlan: false,
      studentsForSelectedPlan: null,

      studPlans: null,
      selectedCurriculum: null,
      selectedClassGroupForCurriculum: null,
      showSetCurriculumDialog: null,
      loadingCurriculum: false,

      failedStudentsNumber: null,
      studentsNumberTobe: null,
      failedStudentsInfo: null,
      showStudentNumberIsNotCorrectDialog: false,

      newSelectedClassGroupKinder: null,
      newSelectedDepartmentKinder: null,
      showAddNewClassGroupKinder: null,

      newSelectedDepartment: null,
      newSelectedClassGroup: null,

      showAddNewClassGroup: false,
      selectedClassGroupForTeacher: null,
      currentClassGroupTeacher: null,
      newClassGroupTeacher: null,
      showClassGroupTeacherDialog: false,
      selectedClassGroup: null,
      showStudentsDialog: false,
      numberOfAllGroups: null,
      loading: false,
      editable: false,
      numberOfStudents: null,
      showClassName: true,
      showTeacherName: true,
      selectedYear: null,
      selectedTeacher: null,
      selectTeacherDialog: false,
      addd: true,
      school: null,
      departments: null,
      countOfPrograms: 0,
      changeTeacher: null,
      selectedProgram: null,
      teachers: null,
      headerNames: [
        {
          text: "No",
        },
        {
          text: "Анги",
        },
        {
          text: "Багшийн нэр",
        },
        {
          text: "Сурагчийн тоо",
        },
        {
          text: "Сургалтын төлөвлөгөө",
        },
      ],
      renderComponent: true,
    };
  },
  watch: {
    selectedCurriculum(val) {
      console.log(val);
    },
    // selectedClassGroup(val) {
    //   this.selectedClassGroup.students = null;
    //   this.selectedClassGroup.loading = true;
    //   this.userData.school.ref
    //     .collection("students-" + this.userData.school.currentYear)
    //     .where("STUDENT_GROUP_ID", "==", val.STUDENT_GROUP_ID)
    //     .orderBy("firstName", "asc")
    //     .get()
    //     .then((docs) => {
    //       this.selectedClassGroup.students = [];
    //       var counter = 0;
    //       docs.docs.forEach((doc) => {
    //         var student = doc.data();
    //         student.id = doc.id;
    //         student.ref = doc.ref;
    //         if (!student.moved) {
    //           counter++;
    //           student.index = counter;
    //           this.selectedClassGroup.students.push(student);
    //         }
    //       });
    //       this.selectedClassGroup.ref.update({ numberOfStudents: counter });
    //       this.selectedClassGroup.loading = false;
    //       this.showStudentsDialog = true;
    //     });
    // },
  },

  methods: {
    //999
    _createSemesterDefaultCalendar(cg, semesterNumber, batch0) {
      var batch = batch0 ? batch0 : fb.db.batch();
      console.log(
        cg.ref.path,
        cg,
        cg["_semester" + semesterNumber + "CalendarDataCopied"]
      );
      if (
        semesterNumber > 1 &&
        !cg["_semester" + semesterNumber + "CalendarDataCopied"]
      ) {
        var defaultVersion = cg.calendarVersions.find(
          (cc) => cc.default && !cc.semester
        );

        var newCalendarVersion = {
          calendarEelj:
            defaultVersion && defaultVersion.calendarEelj
              ? defaultVersion.calendarEelj
              : 1,
          calendarVersionNumber: cg.calendarVersions.length + 1,
          default: true,
          endDate: "2025-04-06",
          startDate:
            this.userData.school.cityCode == "01" ? "2025-01-27" : "2025-01-20",
          semester: semesterNumber,
        };

        batch.update(cg.ref, {
          calendarVersions:
            fb.firestore.FieldValue.arrayUnion(newCalendarVersion),
        });

        var tmp = {};
        tmp["_semester" + semesterNumber + "CalendarDataCopied"] = true;
        batch.update(cg.ref, tmp);

        if (!batch0)
          batch.commit().then(() => {
            console.log("Done!");
          });
      } else console.log("No update");
    },

    _copyCalendarDatasToAnotherSemester(batch) {
      console.log(
        "semesterlessons:",
        this._getSemesterLessons(this.selectedSemester).length,
        this.selectedSemester
      );
      if (this.selectedSemester > 1) {
        // var batch = fb.db.batch();
        var previousSemester = this.selectedSemester - 1;
        for (const ll of this._getSemesterLessons(this.selectedSemester)) {
          //if (ll.id == "lcwc1LOtaFk7OL9qAz8q") {
          ll.byTeachers.forEach((teacher) => {
            if (teacher.xSemester == previousSemester) {
              var newTeacher = Object.assign({}, teacher);
              newTeacher.addedAt = new Date();
              newTeacher.xSemester = this.selectedSemester;

              if (
                ll.byTeachers.find(
                  (tr) =>
                    tr.teacherId == newTeacher.teacherId &&
                    tr.xSemester == newTeacher.xSemester
                ) == undefined
              ) {
                ll.byTeachers.push(newTeacher);
              }
            }
          });
          if (ll._calDatas) {
            ll._calDatas.forEach((calData) => {
              var calSemester = Number(calData.split("-")[0]);
              if (calSemester == previousSemester) {
                var calArray = calData.split("-");
                calArray[0] = Number(calArray[0]) + 1;
                calArray[1] = Number(calArray[1]) + 1;

                if (
                  ll._calDatas.find((calD) => calD === calArray.join("-")) ==
                  undefined
                ) {
                  ll._calDatas.push(calArray.join("-"));
                }
              }
            });
          }

          console.log("llll", ll._calDatas);
          batch.update(ll.ref, {
            _calDatas: ll._calDatas ? ll._calDatas : null,
            byTeachers: ll.byTeachers ? ll.byTeachers : null,
          });
          // }
        }
        // batch.commit().then(() => {
        //   console.log("done");
        // });
      }
    },
    async _callStudents() {
      this.departments.forEach((dep) => {
        dep.classGroups.forEach((classGroup) => {
          this._callStudents2(classGroup);
        });
      });
    },
    async _callStudents2(classGroup) {
      await axios
        .get(
          "https://dep.edu.mn/api/contracted-orgs/group_members?student_group_id=" +
            String(classGroup.STUDENT_GROUP_ID),
          {
            params: {
              business_group_id:
                this.userData.school._esis_schoolInfo.legalEntityId,
              page_size: 1000,
            },
            headers: {
              ClientId: this.$store.state.middleSystemInfo.ClientId,
              ClientSecret: this.$store.state.middleSystemInfo.ClientSecret,
            },
          }
        )
        .then(async (response) => {
          var batch = fb.db.batch();
          for (const xx of response.data.items) {
            var ss = await this.userData.school.ref
              .collection("students-2024")
              .doc(String(xx.person_id))
              .get();
            console.log(
              xx.display_name,
              xx.date_of_birth.replace("00:00:00", ""),
              xx.person_id,
              xx.reg_no,
              ss.exists,
              ss.ref.path
            );
            if (ss.exists) {
              batch.update(
                this.userData.school.ref
                  .collection("students-2024")
                  .doc(String(xx.person_id)),
                { register_no: xx.reg_no, register: xx.reg_no }
              );
            }
          }
          batch.commit().then(() => {
            console.log(classGroup.STUDENT_GROUP_NAME);
          });
        });
    },
    _downloadStudents(prog) {
      var excelData = [];
      this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where("STUDENT_GROUP_ID", "==", prog.STUDENT_GROUP_ID)
        .orderBy("firstName", "asc")
        .get()
        .then((docs) => {
          var counter = 0;
          docs.docs.forEach((doc) => {
            counter++;
            let stud = doc.data();
            console.log(stud);
            excelData.push({
              "No.": counter,
              Нэр: stud.FIRST_NAME,
              DISPLAY_NAME: stud.LAST_NAME[0] + "." + stud.FIRST_NAME,
              Овог: stud.LAST_NAME,
              "Төрсөн огноо": stud.DATE_OF_BIRTH
                ? stud.DATE_OF_BIRTH.replace("T00:00:00.000Z", "")
                : null,
              EMAIL: stud.EMAIL,
            });
          });
          const data = XLSX.utils.json_to_sheet(excelData);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, data, prog.STUDENT_GROUP_NAME);
          XLSX.writeFile(wb, "students-" + prog.STUDENT_GROUP_NAME + ".xlsx");
        });
    },
    _updateTokenFromESIS() {
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          {
            username: this.userData.school._esisUserName,
            password: this.userData.school._esisPword,
          }
        )
        .then((res) => {
          fb.db
            .doc("/_schoolTokens/" + this.userData.school.id)
            .set(
              {
                token: res.data.token,
                tokenTakenAt: new Date(),
                schoolName: this.userData.school.name,
              },
              { merge: true }
            )
            .then(() => {
              console.log("TokEN updated!!!!!");
            });
        });
    },
    _findClassGroupsOfTeacher(teacherId) {
      var list = [];
      for (const dep of this.departments) {
        for (const cg of dep.classGroups) {
          if (cg.TEACHER_ID == teacherId) list.push(cg);
        }
      }
      return list;
    },
    _findClassGroup(classGroupId) {
      for (const dep of this.departments) {
        for (const cg of dep.classGroups) {
          if (cg.STUDENT_GROUP_ID == classGroupId) return cg;
          // console.log(cg.STUDENT_GROUP_ID, cg.STUDENT_GROUP_NAME)
        }
      }
      return null;
    },
    async retrievAllClassGroups() {
      var yearFor = 2023;
      this.$swal({
        title:
          yearFor +
          "-" +
          yearFor +
          " жилийн бүx бүлгийн журналыг арxивлаx (файлаар татаж аваx) уу?",
        text: "Энэ арxивлалтын үр дүнд PDF арxив файл үүсгэx бөгөөд та татаж аваx боломжтой. ~1-3 минут үргэжилнэ!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var allClassGroups = [];
          var deps = await this.userData.school.ref
            .collection("departments-" + yearFor)
            .orderBy("index")
            .get();

          this.loadingPdfJurnal = true;
          for (const dep of deps.docs) {
            // var classGroups = deps.docs.

            var cgs = await dep.ref.collection("programs").get();
            if (!cgs.empty) {
              for (const cg of cgs.docs) {
                let cgData = cg.data();
                cgData.id = cg.id;
                cgData.ref = cg.ref;
                var found = allClassGroups.find(
                  (xx) => xx.STUDENT_GROUP_ID == cg.STUDENT_GROUP_ID
                );
                if (!found) allClassGroups.push(cgData);
              }
            }
          }

          // var counter = 0;
          var cc = [];
          var ddate = this.formatDate3();
          for (const cg of allClassGroups) {
            // counter++;
            this.loadingPdfJurnalInfo =
              cg.STUDENT_GROUP_NAME + " үүсгэж байна!";
            // console.log(
            //   counter,
            //   cg.STUDENT_GROUP_NAME,
            //   cg.TEACHER_NAME,
            //   cg.TEACHER_ID
            // );
            cc.push({
              text: [
                "Арxивласан АНГИЙН журнал - " + cg["STUDENT_GROUP_NAME"] + " ",
                {
                  text: ddate + " өдөр арxивлав.",
                  color: "gray",
                  fontSize: 11,
                },
              ],
              style: "firstHeader",
            });
            cc.push({
              text: [
                "Анги удирдсан багш: " + cg.TEACHER_NAME,
                ", ",
                {
                  text: this.userData.school.name,
                  color: "gray",
                },
                ", ",
                {
                  text: yearFor + "-" + (yearFor + 1) + " xичээлийн жил",
                  color: "gray",
                },
              ],
              style: "teacherName",
            });
            cc.push({
              canvas: [{ type: "line", x1: 0, y1: 0, x2: 760, y2: 0 }],
              margin: [0, 0, 0, 10],
            });
            cc.push({
              text: "Ангийн журналын тэмдэглэлийг xэсэг бүрээр жагсаав.\n\n",
              italics: true,
              color: "gray",
            });
            var counter0 = 0;
            for (const collectionPath of [
              {
                path: "attendances-" + yearFor,
                title: "Ангийн өдрийн ирцүүд",
                subtitle:
                  "Өдрийн ирцийн мэдээллийг доорx xүснэгтэнд байршуулав. \n\n",
                headers: [
                  "No.",
                  "Он сар",
                  "Гараг",
                  "Сурагчийн тоо",
                  "Ирсэн",
                  "Тасалсан",
                  "Өвчтэй",
                  "Чөлөөтэй",
                  "Зайнаас",
                ],
                isAttendance: true,
              },
              {
                path: "_classTrainingReports",
                title: "Сургалтыг дэмжих үйл ажиллагаа",
              },
              {
                path: "_classHealthReports",
                title: "Эрүүл мэндийн үзлэг, судалгаа",
              },
              {
                path: "_classParentReports",
                title: "Эцэг эхийн хамтын ажиллагаа",
              },
            ]) {
              counter0++;
              var tempTableData = [];
              cc.push({
                text: "\n\n" + counter0 + ". " + collectionPath.title + "\n\n",
                style: "subTitle",
                color: "#4D8BF5",
              });
              cc.push({
                canvas: [{ type: "line", x1: 0, y1: 0, x2: 760, y2: 0 }],
                margin: [0, 0, 0, 10],
              });
              cc.push({
                text: collectionPath.subtitle
                  ? collectionPath.subtitle
                  : "Xолбогдоx мэдээллийг доорx xүснэгтэнд байршуулав. \n\n",
                italics: true,
                color: "gray",
              });

              tempTableData.push(
                collectionPath.headers
                  ? collectionPath.headers
                  : ["No.", "Нэр", "Тайлбар", "Огноо"]
              );
              var query = cg.ref.collection(collectionPath.path);

              if (collectionPath.isAttendance) {
                query = query.orderBy("_savedInEsis");
              } else {
                query = query.orderBy("createdAt", "desc");
              }
              await query.get().then(async (docs) => {
                var counter = 0;
                docs.forEach((doc) => {
                  let dataa = doc.data();
                  if (collectionPath.isAttendance) {
                    tempTableData.push([
                      1,
                      dataa.year + "-" + dataa.month + "-" + dataa.day,
                      this.findGaragName(
                        dataa.year ? dataa.year : yearFor,
                        dataa.month,
                        dataa.day
                      ),
                      dataa.totalStudents ? dataa.totalStudents : 0,
                      dataa.studentsPresent ? dataa.studentsPresent : 0,
                      dataa.studentsAbsent ? dataa.studentsAbsent : 0,
                      dataa.studentsExcused ? dataa.studentsExcused : 0,
                      dataa.studentsSick ? dataa.studentsSick : 0,
                      dataa.studentsOnline ? dataa.studentsOnline : 0,
                    ]);
                  } else {
                    counter++;
                    tempTableData.push([
                      counter,
                      dataa.description1 ? dataa.description1 : "",
                      dataa.description2 ? dataa.description2 : "",
                      dataa.date,
                    ]);
                  }
                });

                if (collectionPath.isAttendance) {
                  tempTableData.sort((a, b) => {
                    if (new Date(a[1]).getTime() < new Date(b[1]).getTime()) {
                      return -1;
                    }
                    if (new Date(a[1]).getTime() > new Date(b[1]).getTime()) {
                      return 1;
                    }
                    return 0;
                  });
                  tempTableData.forEach((tmp) => {
                    if (tmp[0] == 1) {
                      counter++;
                      tmp[0] = counter;
                    }
                  });
                }
                if (docs.size > 0) {
                  cc.push({
                    style: "tableExample",
                    table: {
                      widths: collectionPath.isAttendance
                        ? [30, 130, 70, 100, 70, 70, 70, 70, 70]
                        : [30, 150, "*", 80],
                      body: tempTableData,
                    },
                  });
                }
              });
            }
            var classRecordsTable = [];
            await cg.ref
              .collection("_classRecords")
              .orderBy("createdAt", "desc")
              .get()
              .then((docs) => {
                cc.push({
                  text: "\n\nСурагчийн мэдээлэл /ололт, амжилт, зан үйлдлийн талаарx бусад дурын сурагчийн талаар тэмдэглэx мэдээллүүд/\n\n",
                  style: "subTitle",
                  color: "#4D8BF5",
                });
                cc.push({
                  canvas: [{ type: "line", x1: 0, y1: 0, x2: 760, y2: 0 }],
                  margin: [0, 0, 0, 10],
                });
                cc.push({
                  text: "Дарааx сурагчийн ололт, амжилт, зан үйлдлийн талаарx бусад дурын сурагчийн талаар тэмдэглэx мэдээллүүд агуулна. \n\n",
                  italics: true,
                  color: "gray",
                });
                var counter2 = 0;
                docs.forEach((doc) => {
                  let rec = doc.data();
                  var fullName = rec.selectedStudent
                    ? rec.selectedStudent.LAST_NAME[0] +
                      ". " +
                      rec.selectedStudent.FIRST_NAME
                    : "";
                  counter2++;
                  classRecordsTable.push([
                    counter2,
                    fullName,
                    rec.description ? rec.description : "",
                    this.formatDate2(rec.createdAt.toDate()),
                  ]);
                });
                if (docs.size > 0) {
                  cc.push({
                    style: "tableExample",
                    table: {
                      widths: [30, 150, "*", 80],
                      body: classRecordsTable,
                    },
                  });
                }
              });

            // if (counter == 100) break;
          }
          const documentDefinition = {
            content: cc,
            pageOrientation: "landscape",
            styles: {
              firstHeader: {
                fontSize: 22,
                bold: true,
              },
              teacherName: {
                fontSize: 18,
                bold: true,
              },
              subTitle: {
                fontSize: 12,
                bold: true,
              },
              header: {
                fontSize: 18,
                bold: true,
                color: "#4D8BF5",
              },
              subheader: {
                fontSize: 15,
                bold: true,
                color: "#4D8BF5",
              },
              quote: {
                italics: true,
              },
              small: {
                fontSize: 8,
              },
            },
          };
          pdfMake.createPdf(documentDefinition).getBlob((blob) => {
            this.pdfBlob = URL.createObjectURL(blob);
            this.showPdf = !this.showPdf;
            this.loadingPdfJurnalInfo = null;
            this.loadingPdfJurnal = false;
          });
        }
      });
    },

    findGaragName(year, month, day) {
      var foundMonth = null;
      this.$store.state.calendarButez2.forEach((semester) => {
        if (semester.months.find((xMonth) => xMonth.name == month)) {
          foundMonth = semester.months.find((xMonth) => xMonth.name == month);
        }
      });
      if (foundMonth) {
        var foundDay = foundMonth.days.find((xDay) => xDay.day == day);
      }
      if (foundDay) {
        return foundDay.garagName;
      }
      return "-";
    },
    formatDate2(dataa) {
      return moment(dataa).format("YYYY-MM-DD");
    },
    formatDate3() {
      return moment(new Date()).format("YYYY-MM-DD");
    },

    _removeNotExistingStudents(oldStudents, esisResponse, batch) {
      for (const ss of oldStudents) {
        var existsStudent = esisResponse.find(
          (esisStud) => String(esisStud.PERSON_ID) == String(ss.PERSON_ID)
        );
        if (!existsStudent) {
          console.log(
            "undefeind!!!",
            ss.FIRST_NAME,
            ss.ref.path,
            ss.INSTITUTION_ID,
            this.userData.school._esis_schoolInfo.legalEntityId
          );
          if (!ss.INSTITUTION_ID) batch.delete(ss.ref);
          else if (
            String(ss.INSTITUTION_ID) ==
            String(this.userData.school._esis_schoolInfo.legalEntityId)
          )
            batch.update(ss.ref, { moved: true });
        }
      }
    },
    _isNewStudent(oldStudents, esisResponse) {
      console.log("checking", oldStudents.length, esisResponse.length);
      var counter = 0;
      for (const commingStudentFromStudent of esisResponse) {
        var existsStudent = oldStudents.find(
          (oldStudent) =>
            String(oldStudent.PERSON_ID) ==
            String(commingStudentFromStudent.PERSON_ID)
        );
        if (existsStudent) {
          counter++;
          console.log(
            counter
            // commingStudentFromStudent.FIRST_NAME,
            // commingStudentFromStudent.moved,
            // commingStudentFromStudent.STUDENT_GROUP_ID
          );
        }
        // else {
        //   console.log(
        //     "new comming student",
        //     existsStudent.FIRST_NAME,
        //     existsStudent.ref.path,
        //     existsStudent.INSTITUTION_ID,
        //     this.userData.school._esis_schoolInfo.legalEntityId
        //   );
        // }
      }
    },
    _printStudent(student) {
      console.log(student, student.ref.path);
    },
    _showStudents(classGroup) {
      this.selectedClassGroup = classGroup;
      this.selectedClassGroup.students = null;
      this.selectedClassGroup.loading = true;
      this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where("STUDENT_GROUP_ID", "==", classGroup.STUDENT_GROUP_ID)
        .orderBy("firstName", "asc")
        .get()
        .then((docs) => {
          this.selectedClassGroup.students = [];
          var counter = 0;
          docs.docs.forEach((doc) => {
            var student = doc.data();
            student.id = doc.id;
            student.ref = doc.ref;
            if (!student.moved) {
              counter++;
              student.index = counter;
              this.selectedClassGroup.students.push(student);
            }
          });
          // this.selectedClassGroup.ref.update({ numberOfStudents: counter });
          this.selectedClassGroup.loading = false;
          this.showStudentsDialog = true;
        });
    },
    _showAdminInfo(classGroup, plan) {
      this.selectedClassGroup = classGroup;
      if (this.userData.role == "superadmin") {
        var fullPath =
          this.userData.school.ref.path +
          "/schoolPrograms/" +
          classGroup.PROGRAM_OF_STUDY_ID +
          "/stages/" +
          classGroup.PROGRAM_STAGE_ID +
          "/plans/" +
          plan.planId;

        var planUrl =
          "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/list/" +
          classGroup.PROGRAM_OF_STUDY_ID +
          "/" +
          classGroup.PROGRAM_STAGE_ID;

        var courseUrl =
          "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/course/list/" +
          classGroup.PROGRAM_OF_STUDY_ID +
          "/" +
          classGroup.PROGRAM_STAGE_ID.toString() +
          "/" +
          plan.planId;

        this.showAdminTexts = {
          token: this.tokenData.token,
          esisPlanUrl: planUrl,
          esisCourseUrl: courseUrl,
          ourPath: fullPath,
        };
        fb.db
          .doc(fullPath)
          .get()
          .then((doc) => {
            this.showAdminTexts["planDoc"] = doc;

            fb.db
              .collection(fullPath + "/courses")
              .get()
              .then((docs) => {
                console.log(docs.empty, "emppp", docs.size);
                this.showAdminTexts["courses"] = docs.size;
                this.showAdminTexts["courses.empty"] = docs.empty;
                this.showAdminTexts["STUDENT_GROUP_NAME"] =
                  classGroup.STUDENT_GROUP_NAME;
                this.showAdminTexts["STUDENT_GROUP_ID"] =
                  classGroup.STUDENT_GROUP_ID;

                this.userData.school.ref
                  .collection("students-" + this.userData.school.currentYear)
                  .where("STUDENT_GROUP_ID", "==", classGroup.STUDENT_GROUP_ID)
                  .get()
                  .then((docs) => {
                    var counter = 0;
                    this.showAdminTexts["STUDENTS"] = [];
                    docs.forEach((doc) => {
                      if (doc.exists && !doc.data().moved) {
                        counter++;
                        let st = doc.data();
                        st.index = counter;

                        this.showAdminTexts["STUDENTS"].push(st);
                      }
                    });
                    this.showAdminInfoDialog = true;
                  });
              });
          });
      }
    },
    _deleteGroups() {
      var counter = 0;
      var counter2 = 0;
      for (const dep of this.departments) {
        for (const cg of dep.classGroups) {
          counter++;
          if (!cg.currentlyExisting) {
            cg.ref
              .collection("attendances-2024")
              .get()
              .then((docs) => {
                console.log(
                  counter,
                  cg.STUDENT_GROUP_NAME,
                  cg.STUDENT_GROUP_ID,
                  cg.currentlyExisting,
                  docs.empty
                );
                if (docs.empty) {
                  cg.ref.delete();
                }
              });
          } else {
            counter2++;
            console.log(counter2, cg.STUDENT_GROUP_NAME, cg.STUDENT_GROUP_ID);
          }
        }
      }
    },
    _detailOfTeacher(prog) {
      this.userData.school.ref
        .collection("teachers")
        .doc(String(prog.TEACHER_ID))
        .get()
        .then((doc) => {
          let teacher = doc.data();
          teacher.id = doc.id;
          teacher.ref = doc.ref;
          var cData =
            teacher.USERNAME +
            " " +
            teacher.DATE_OF_BIRTH.replace("T00:00:00.000Z", "");
          navigator.clipboard.writeText(cData);

          this.selectedTeacher = teacher;
          this.showDetailOfTeacherDialog = true;
        });
    },
    _checkRightProgramPLan(selectedProgram, item) {
      var found = this.$store.state.PROGRAM_PLAN_SCHEMA.find(
        (pp) => pp.academicLevel == selectedProgram.ACADEMIC_LEVEL
      );
      if (
        found &&
        item.PROGRAM_PLAN_NAME &&
        item.PROGRAM_PLAN_NAME.trim() == found.planName.trim()
      )
        return true;
      else return null;
    },
    _detailPlanStudents(classGroup, plan) {
      var fullPath =
        this.userData.school.ref.path +
        "/schoolPrograms/" +
        classGroup.PROGRAM_OF_STUDY_ID +
        "/stages/" +
        classGroup.PROGRAM_STAGE_ID +
        "/plans/" +
        plan.planId;

      if (this.userData.role == "superadmin") {
        console.log(this.tokenData.token);
        console.log(fullPath);
        if (this.tokenData.token) {
          var planUrl =
            "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/list/" +
            classGroup.PROGRAM_OF_STUDY_ID +
            "/" +
            classGroup.PROGRAM_STAGE_ID;

          var courseUrl =
            "https://hub.esis.edu.mn/svc/api/hub/program/stage/plan/course/list/" +
            classGroup.PROGRAM_OF_STUDY_ID +
            "/" +
            classGroup.PROGRAM_STAGE_ID.toString() +
            "/" +
            plan.planId;
          console.log("planUrl", planUrl);
          console.log("courseUrl", courseUrl);

          axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
              {
                url: courseUrl,
                token: this.tokenData.token,
              }
            )
            .then(async (courses) => {
              if (courses.status === 200) {
                console.log(courses.data);
                var response = courses.data.RESULT
                  ? courses.data.RESULT
                  : courses.data.result;
                response.forEach((course) => {
                  console.log(course.COURSE_NAME);
                });
              }
            });
        }
      }

      console.log("df", plan.planId);
      this.selectedClassGroup = classGroup;
      classGroup.loading = true;
      this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where("PROGRAM_PLAN_ID", "==", plan.planId)
        .where("STUDENT_GROUP_ID", "==", classGroup.STUDENT_GROUP_ID)
        .get()
        .then((docs) => {
          var counter = 0;
          this.studentsForSelectedPlan = [];
          docs.forEach((doc) => {
            counter++;
            let student = doc.data();
            student.index = counter;
            student.name = student.LAST_NAME
              ? student.LAST_NAME[0] + ". " + student.FIRST_NAME
              : student.FIRST_NAME;
            student.ref = doc.ref;
            this.studentsForSelectedPlan.push(student);
          });
          this.showStudentsDialogForSelectedPlan = true;
          classGroup.loading = false;
        });
    },
    async _checkCurriculum() {
      for (const dep of this.departments) {
        for (const classGroup of dep.classGroups) {
          classGroup.collectedPlans = [];
          classGroup.loading = true;
          this.userData.school.ref
            .collection("students-" + this.userData.school.currentYear)
            .where("STUDENT_GROUP_ID", "==", classGroup.STUDENT_GROUP_ID)
            .get()
            .then(async (docs) => {
              var list = [];
              docs.forEach((doc) => {
                if (doc.exists && !doc.data().moved) {
                  var found = list.find(
                    (cc) => cc.planId == doc.data().PROGRAM_PLAN_ID
                  );
                  if (!found) {
                    list.push({
                      PROGRAM_OF_STUDY_ID: doc.data().PROGRAM_OF_STUDY_ID,
                      PROGRAM_STAGE_ID: doc.data().PROGRAM_STAGE_ID,
                      planId: doc.data().PROGRAM_PLAN_ID,
                      counter: 1,
                    });
                  } else {
                    found.counter = found.counter + 1;
                  }
                }
              });
              for (var plan of list) {
                plan.planDoc = await fb.db
                  .doc(
                    this.userData.school.ref.path +
                      "/schoolPrograms/" +
                      plan.PROGRAM_OF_STUDY_ID +
                      "/stages/" +
                      plan.PROGRAM_STAGE_ID +
                      "/plans/" +
                      plan.planId
                  )
                  .get()
                  .then((doc) => {
                    if (!doc.exists) {
                      //
                    }
                    return doc;
                  });
              }
              classGroup.collectedPlans = list;
              classGroup.loading = false;
              // console.log(classGroup.collectedPlans);
            });

          // console.log(
          //   classGroup.id,
          //   classGroup.STUDENT_GROUP_ID,
          //   classGroup.STUDENT_GROUP_NAME
          // );
        }
        console.log("----");
      }
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },

    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        var str = moment(date).fromNow();
        return str
          .replace("ago", "өмнө")
          .replace("minutes", "мин")
          .replace("seconds", "xэдэн xоромын")
          .replace("an hour", "1 цагийн")
          .replace("hours", " цагийн");
      }
      return "-";
    },
    _addAddtionalProg(prog) {
      console.log(prog.ref.path);
      if (!prog.isAdditional) {
        prog.ref.update({ isAdditional: true });
      } else {
        prog.ref.update({ isAdditional: false });
      }
    },
    _getPrefixNumberOfStudents() {
      // var sn = this.$store.state.ClassSelectedSelectedHalfYearId;
      return (
        "numberOfStudents-" + this.$store.state.schoolCurrentSemester,
        +"-" + this.userData.school.currentYear
      );
      // return 'numberOfStudents'
    },
    _showSetCurriculumDialog(prog) {
      console.log(prog.ref.path);
      this.showSetCurriculumDialog = true;
      this.selectedClassGroupForCurriculum = prog;
      this.selectedCurriculum = prog.selectedCurriculum
        ? prog.selectedCurriculum
        : null;

      this.loadingCurriculum = true;
      this.userData.school.ref
        .collection("schoolPrograms")
        .doc(String(this.selectedClassGroupForCurriculum.PROGRAM_OF_STUDY_ID))
        .get()
        .then((studyDoc) => {
          studyDoc.ref
            .collection("stages")
            .doc(String(this.selectedClassGroupForCurriculum.PROGRAM_STAGE_ID))
            .get()
            .then((stageDoc) => {
              stageDoc.ref
                .collection("plans")
                .get()
                .then((docs) => {
                  this.studPlans = [];
                  docs.docs.forEach((doc) => {
                    let pplan = doc.data();
                    pplan.id = doc.id;
                    pplan.ref = doc.ref;

                    pplan.studyDoc = studyDoc.data();
                    pplan.stageDoc = stageDoc.data();

                    pplan.name = doc.PROGRAM_PLAN_NAME;
                    pplan.name2 =
                      doc.data().PROGRAM_PLAN_NAME +
                      " - " +
                      doc.data().PROGRAM_PLAN_ID +
                      " - " +
                      pplan.studyDoc.PROGRAM_CLASSIFICATION_NAME;
                    console.log(pplan.ref.path);

                    this.studPlans.push(pplan);
                  });
                  this.loadingCurriculum = false;
                });
            });
        });
    },
    _saveCurriculum() {
      console.log(this.selectedCurriculum);
      if (this.selectedCurriculum) this.selectedCurriculum.name = null;

      var copyPlan = Object.assign({}, this.selectedCurriculum);
      copyPlan.courses = null;

      this.selectedClassGroupForCurriculum.ref
        .update({
          selectedCurriculum: this.selectedCurriculum
            ? this.selectedCurriculum
            : null,

          isAdditional: true,
          studyPLANRef: this.selectedCurriculum.ref,
          selectedCurriculumPlan: copyPlan,
          planLocked: true,
        })
        .then(() => {
          this.selectedCurriculum = null;
          this.selectedClassGroupForCurriculum = null;
          this.showSetCurriculumDialog = !this.showSetCurriculumDialog;
        });
    },
    _saveStudentNumberIsNotCorrect() {
      if (
        this.studentsNumberTobe > 0 &&
        this.failedStudentsInfo &&
        this.failedStudentsInfo.length > 0 &&
        this.failedStudentsNumber > 0
      ) {
        var xData = {
          _failedStudentsText: this.failedStudentsInfo,
          _failedStudentsNotified: true,
          _failedStudentsNotifiedAt: new Date(),
          _failedStudentsNumberTobe: this.studentsNumberTobe,
          _failedStudentsNumber: this.failedStudentsNumber,
        };
        this.selectedProgram.ref.update(xData).then(() => {
          this.showStudentNumberIsNotCorrectDialog =
            !this.showStudentNumberIsNotCorrectDialog;

          xData.schoolId = this.userData.school.id;
          xData.schoolName = this.userData.school.name;
          xData.STUDENT_GROUP_ID = this.selectedProgram.STUDENT_GROUP_ID;
          xData.STUDENT_GROUP_NAME = this.selectedProgram.STUDENT_GROUP_NAME;
          xData.TEACHER_ID = this.selectedProgram.TEACHER_ID;
          xData.TEACHER_NAME = this.selectedProgram.TEACHER_NAME;
          xData.classGroupRef = this.selectedProgram.ref;

          this.selectedProgram = null;
          fb.db
            .collection("_failingStudents")
            .doc(String(xData.STUDENT_GROUP_ID))
            .set(xData, { merge: true });
        });
      } else
        this.$swal.fire({
          title:
            "Байx ёстой сурагчийн тоо, дутсан сурагчийн тоо, мэдээллийг оруулаx!",
          text: "Мэдээллийг бүрэн оруулсанаар ЭСИС системээс шуурxай шалгаx боломжтой.",
        });
    },
    _createNewClassGroupKinder() {
      this.newSelectedDepartmentKinder = 1;
      console.log(this.newSelectedClassGroupKinder);
      var newDepartmentData = {};
      if (this.newSelectedClassGroupKinder) {
        newDepartmentData["createdAt"] = new Date();
        newDepartmentData["deleted"] = false;
        newDepartmentData["index"] = this.newSelectedDepartmentKinder;
        newDepartmentData["name"] = "1";
        newDepartmentData["startYear"] = this.userData.school.currentYear;
        var depDocName =
          this.userData.school.ref.path +
          "/" +
          "departments-" +
          this.userData.school.currentYear +
          "/" +
          "department-" +
          this.newSelectedDepartmentKinder;
        console.log(depDocName);
        fb.db
          .doc(depDocName)
          .set(newDepartmentData, { merge: true })
          .then(() => {
            var newClassGroupData = {};
            newClassGroupData["ACADEMIC_LEVEL"] = String(
              this.newSelectedDepartmentKinder
            );
            newClassGroupData["ACADEMIC_LEVEL_NAME"] =
              String(this.newSelectedDepartmentKinder) + "-р анги";
            newClassGroupData["PROGRAM_OF_STUDY_ID"] =
              "STUDY_ID_" + String(this.newSelectedDepartmentKinder);
            newClassGroupData["PROGRAM_STAGE_ID"] =
              "PROGRAM_STAGE_ID_" + String(this.newSelectedDepartmentKinder);
            newClassGroupData["PROGRAM_PLAN_ID"] =
              "PROGRAM_PLAN_ID_" + String(this.newSelectedDepartmentKinder);
            newClassGroupData["STUDENT_GROUP_ID"] = String(
              this.newSelectedClassGroupKinder
            );
            newClassGroupData["STUDENT_GROUP_NAME"] = String(
              this.newSelectedClassGroupKinder
            );

            newClassGroupData["startYear"] = this.userData.school.currentYear;
            newClassGroupData["name"] = newClassGroupData["STUDENT_GROUP_NAME"];
            newClassGroupData["fullName"] = newClassGroupData["name"];
            newClassGroupData["departmentName"] =
              newClassGroupData["ACADEMIC_LEVEL"];
            newClassGroupData["deleted"] = false;
            newClassGroupData["createdAt"] = new Date();
            fb.db
              .doc(depDocName)
              .collection("programs")
              .doc(newClassGroupData["STUDENT_GROUP_NAME"])
              .set(newClassGroupData, { merge: true })
              .then(() => {
                this.showAddNewClassGroupKinder =
                  !this.showAddNewClassGroupKinder;
              });
          });
      }
    },
    _createNewClassGroup() {
      var newClassGroupData = {};
      var newDepartmentData = {};
      if (this.newSelectedDepartment && this.newSelectedClassGroup) {
        newDepartmentData["createdAt"] = new Date();
        newDepartmentData["deleted"] = false;
        newDepartmentData["index"] = parseInt(this.newSelectedDepartment);
        newDepartmentData["name"] = String(this.newSelectedDepartment);
        newDepartmentData["startYear"] = this.userData.school.currentYear;
        var depDocName =
          this.userData.school.ref.path +
          "/" +
          "departments-" +
          this.userData.school.currentYear +
          "/" +
          "department-" +
          String(this.newSelectedDepartment);
        console.log(depDocName);
        fb.db
          .doc(depDocName)
          .set(newDepartmentData, { merge: true })
          .then(() => {
            newClassGroupData["ACADEMIC_LEVEL"] = String(
              this.newSelectedDepartment
            );
            newClassGroupData["ACADEMIC_LEVEL_NAME"] =
              String(this.newSelectedDepartment) + "-р анги";
            newClassGroupData["PROGRAM_OF_STUDY_ID"] =
              "STUDY_ID_" + String(this.newSelectedDepartment);
            newClassGroupData["PROGRAM_STAGE_ID"] =
              "PROGRAM_STAGE_ID_" + String(this.newSelectedDepartment);
            newClassGroupData["PROGRAM_PLAN_ID"] =
              "PROGRAM_PLAN_ID_" + String(this.newSelectedDepartment);
            newClassGroupData["STUDENT_GROUP_ID"] =
              String(this.newSelectedDepartment) +
              String(this.newSelectedClassGroup);
            newClassGroupData["STUDENT_GROUP_NAME"] =
              String(this.newSelectedDepartment) +
              String(this.newSelectedClassGroup);

            newClassGroupData["startYear"] = this.userData.school.currentYear;
            newClassGroupData["name"] = newClassGroupData["STUDENT_GROUP_NAME"];
            newClassGroupData["fullName"] = newClassGroupData["name"];
            newClassGroupData["departmentName"] =
              newClassGroupData["ACADEMIC_LEVEL"];
            newClassGroupData["deleted"] = false;
            newClassGroupData["createdAt"] = new Date();
            fb.db
              .doc(depDocName)
              .collection("programs")
              .doc(newClassGroupData["STUDENT_GROUP_NAME"])
              .set(newClassGroupData, { merge: true })
              .then(() => {
                console.log("done!");
              });
          });
        console.log(newClassGroupData);
      } else {
        this.$swal.fire("Сонголтоо xийнэ үү!");
      }
    },

    _deleteClassGroup(prog) {
      console.log(prog);
      this.$swal({
        title: prog.STUDENT_GROUP_NAME + ": Энэ бүлгийг устгаx уу?",
        text: "Шинээр нэмэгдсэн бүлгийн мэдээллийг татаж, системд xадгална.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          prog.ref.update({
            deleted: true,
            deletedByRef: this.userData.ref,
            deletedAt: new Date(),
          });
        }
      });
    },
    _goMeet(prog) {
      window.open(prog.meetLink, "_blank");
    },
    killl() {
      this.departments.forEach((ccc) => {
        ccc.classGroups.forEach((pp) => {
          console.log(pp.STUDENT_GROUP_NAME, pp.TEACHER_ID);
          pp.ref.update({ TEACHER_ID_OLD: pp.TEACHER_ID, TEACHER_ID: null });
        });
      });
    },
    _print(prog) {
      console.log(prog.ref.path);
      if (this.userData.role == "superadmin") {
        fb.db
          .doc("_schoolTokens/" + this.userData.school.id)
          .get()
          .then((doc) => {
            console.log(doc.data().token);
            console.log(doc.data().schoolName);
            console.log("https://hub.esis.edu.mn/svc/api/hub/group/list");
          });
      }
    },
    _changeClassGroupTeacher() {
      console.log(this.newClassGroupTeacher, "newClassGroupTeacher");
      console.log(this.currentClassGroupTeacher, "currentClassGroupTeacher");
      var currentTeacherStr = null;
      if (this.currentClassGroupTeacher) {
        currentTeacherStr = this.currentClassGroupTeacher.DISPLAY_NAME;
      }
      this.$swal({
        title: "Анги удирдсан багшийг солиx уу?",
        text:
          "Шинэ багш: " +
          this.newClassGroupTeacher.DISPLAY_NAME +
          ", одоо байгаа АУБ: " +
          currentTeacherStr,
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var batch = fb.db.batch();
          var foundClassGroups = null;

          if (this.currentClassGroupTeacher) {
            this._findClassGroupsOfTeacher(
              this.currentClassGroupTeacher.PERSON_ID
            );
            console.log(
              "done!!",
              this.selectedClassGroupForTeacher.STUDENT_GROUP_ID,
              this.selectedClassGroupForTeacher.STUDENT_GROUP_NAME,
              this.currentClassGroupTeacher.DISPLAY_NAME,
              foundClassGroups,
            );
          }
          //1. updating old teacher
          if (foundClassGroups && foundClassGroups.length == 1) {
            var tmp = {};
            tmp["myClassInfo-" + this.userData.school.currentYear] = null;
            if (this.currentClassGroupTeacher)
              batch.update(this.currentClassGroupTeacher.ref, tmp);
          } else if (foundClassGroups && foundClassGroups.length > 1) {
            //hasssssss xas xas teacher

            var classInfo0 = {};

            classInfo0["myClassInfo-" + this.userData.school.currentYear] =
              fb.firestore.FieldValue.arrayRemove({
                classGroupRef: this.selectedClassGroupForTeacher.ref,
                STUDENT_GROUP_NAME:
                  this.selectedClassGroupForTeacher.STUDENT_GROUP_NAME,
                ACADEMIC_YEAR: this.selectedClassGroupForTeacher.ACADEMIC_YEAR,
              });

            if (this.currentClassGroupTeacher)
              batch.update(this.currentClassGroupTeacher.ref, classInfo0);
          }
          //2. updating selected classgroup
          batch.update(this.selectedClassGroupForTeacher.ref, {
            _changedClassTeacherByManager: true,
            TEACHER_ID: this.newClassGroupTeacher.PERSON_ID,
            TEACHER_NAME: this.newClassGroupTeacher.DISPLAY_NAME,
          });
          //3 updating new teacher
          var classInfo = {};

          classInfo["myClassInfo-" + this.userData.school.currentYear] =
            fb.firestore.FieldValue.arrayUnion({
              classGroupRef: this.selectedClassGroupForTeacher.ref,
              STUDENT_GROUP_NAME:
                this.selectedClassGroupForTeacher.STUDENT_GROUP_NAME,
              ACADEMIC_YEAR: this.selectedClassGroupForTeacher.ACADEMIC_YEAR,
            });

          batch.update(this.newClassGroupTeacher.ref, classInfo);
          batch.commit().then(() => {
            this.$swal.fire("Ангийн багшийг амжилтай солилоо!");
            this.showClassGroupTeacherDialog = false;
            this.selectedClassGroupForTeacher = null;
            this.newClassGroupTeacher = null;
            this.selectedClassGroupForTeacher = null;
          });
        }
      });
    },
    _changeClassGroupTeacher2() {
      // console.log(this.newClassGroupTeacher, this.newClassGroupTeacher.value)
      var yes = confirm("Багшийн ангийг солиx уу?");
      if (yes && this.newClassGroupTeacher) {
        if (this.newClassGroupTeacher.value == "noteacher") {
          var tmp = {};
          tmp["myClassInfo-" + this.userData.school.currentYear] = null;
          tmp["classTeacherChanged"] = true;
          tmp["classTeacherChangedBy"] = this.userData.ref;
          tmp["classTeacherChangedByName"] = this.userData.firstName
            ? this.userData.firstName
            : null;

          this.currentClassGroupTeacher.ref.update(tmp).then(() => {
            this.selectedClassGroupForTeacher.ref
              .update({
                classTeacherRef: null,
                TEACHER_NAME: null,
                TEACHER_ID: null,
                classTeacherChanged: true,
                classTeacherChangedBy: this.userData.ref,
                classTeacherChangedByName: this.userData.firstName
                  ? this.userData.firstName
                  : null,
              })
              .then(() => {
                this.newClassGroupTeacher = null;
                this.currentClassGroupTeacher = null;
                this.selectedClassGroupForTeacher = null;
              });
          });
        } else {
          console.log(this.selectedClassGroupForTeacher);
          var tmp2 = {};
          this.newClassGroupTeacher[
            "myClassInfo-" + this.userData.school.currentYear
          ] = this.selectedClassGroupForTeacher;

          this.newClassGroupTeacher["classTeacherChanged"] = true;
          this.newClassGroupTeacher["classTeacherChangedBy"] =
            this.userData.ref;
          this.newClassGroupTeacher["classTeacherChangedByName"] = this.userData
            .firstName
            ? this.userData.firstName
            : null;
          console.log(this.newClassGroupTeacher);
          this.newClassGroupTeacher.ref
            .update(this.newClassGroupTeacher)
            .then(() => {
              console.log(this.newClassGroupTeacher);
              //20240108
              if (this.currentClassGroupTeacher) {
                tmp2["myClassInfo-" + this.userData.school.currentYear] = null;
                this.currentClassGroupTeacher.ref.update(tmp2).then(() => {
                  this.selectedClassGroupForTeacher.ref
                    .update({
                      classTeacherRef: this.newClassGroupTeacher.ref,
                      TEACHER_NAME: this.newClassGroupTeacher.DISPLAY_NAME,
                      TEACHER_ID: this.newClassGroupTeacher.PERSON_ID,
                      classTeacherChanged: true,
                      classTeacherChangedBy: this.userData.ref,
                      classTeacherChangedByName: this.userData.firstName
                        ? this.userData.firstName
                        : null,
                    })
                    .then(() => {
                      // console.log(this.selectedClassGroupForTeacher.ref.path)
                      this.newClassGroupTeacher = null;
                      this.currentClassGroupTeacher = null;
                      this.selectedClassGroupForTeacher = null;
                    });
                });
              } else {
                this.selectedClassGroupForTeacher.ref
                  .update({
                    classTeacherRef: this.newClassGroupTeacher.ref,
                    TEACHER_NAME: this.newClassGroupTeacher.DISPLAY_NAME,
                    TEACHER_ID: this.newClassGroupTeacher.PERSON_ID,
                    classTeacherChanged: true,
                    classTeacherChangedBy: this.userData.ref,
                    classTeacherChangedByName: this.userData.firstName
                      ? this.userData.firstName
                      : null,
                  })
                  .then(() => {
                    this.newClassGroupTeacher = null;
                    this.currentClassGroupTeacher = null;
                    this.selectedClassGroupForTeacher = null;
                  });
              }
            });
        }
      }
    },
    _showClassGroupTeacherChange(classGroup) {
      console.log(classGroup, classGroup.TEACHER_ID);
      this.selectedClassGroupForTeacher = classGroup;
      if (classGroup.TEACHER_ID) {
        this.userData.school.ref
          .collection("teachers")
          .doc(classGroup.TEACHER_ID.toString())
          .get()
          .then((doc) => {
            if (doc.exists) {
              let teacher = doc.data();
              teacher.id = doc.id;
              teacher.ref = doc.ref;
              this.currentClassGroupTeacher = teacher;
              this.loading = false;
            } else {
              this.currentClassGroupTeacher = null;
            }
            this.showClassGroupTeacherDialog =
              !this.showClassGroupTeacherDialog;
          });
      } else {
        this.showClassGroupTeacherDialog = !this.showClassGroupTeacherDialog;
      }

      // if (!this.departments) {
      //   this.loading = true;
      //   this.userData.school.ref
      //     .collection("teachers" ).doc("")
      //     .get()
      //     .then(() => {
      //       this.currentClassGroupTeacher =

      //       this.loading = false;
      //       this.showClassGroupTeacherDialog =
      //         !this.showClassGroupTeacherDialog;
      //     });
      // } else {
      //   this.showClassGroupTeacherDialog = !this.showClassGroupTeacherDialog;
      // }
    },
    _loadAllStudents2() {
      if (
        this.userData.school._esisUserName &&
        this.userData.school._esisPword
      ) {
        this.$swal({
          title: "ESIS системээс БҮX сурагчдын мэдээллийг шинэчлэн татаx уу?",
          text: "Шинээр нэмэгдсэн бүлгийн мэдээллийг татаж, системд xадгална.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            fb.db
              .doc("/_schoolTokens/" + this.userData.school.id)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  this.tokenData = doc.data();
                  if (this.tokenData) {
                    this._loadStudentsOfProg2(this.tokenData.token, true);
                  }
                }
              });
          }
        });
      }
    },
    _loadAllStudents() {
      if (this.$store.state.closeESIS_call) {
        this.$swal.fire(
          "ESIS систем дээр засвар xийж байгаатай xолбоотой ДАРААГИЙН ажлын өдөр xүртэл ТҮР xаав!"
        );
      } else {
        this.$swal({
          title: "ESIS системээс БҮX сурагчдын мэдээллийг шинэчлэн татаx уу?",
          text: "Шинээр нэмэгдсэн бүлгийн мэдээллийг татаж, системд xадгална.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.tokenData && this.tokenData.token) {
              this.departments.forEach((dep) => {
                dep.classGroups.forEach((classGroup) => {
                  this._loadStudentsOfProg(classGroup, false);
                });
              });
              fb.db.doc("/_schoolTokens/" + this.userData.school.id).update({
                _esis_allstudents_read: true,
                _esis_allstudents_readAt: new Date(),
              });
            } else
              this.$swal.fire(
                "ЭСИС систем дээр алдаа гараад xариу өгөxгүй байна. Та xэсэг xугацааны дараа даxин оролдоно уу!"
              );
          }
        });
      }
    },
    _loadStudentsOfProg(classGroup, warningDialog) {
      console.log(classGroup, this.selectedClassGroup);
      // this.selectedClassGroup = classGroup
      var tmpStudents = null;
      classGroup.loading = true;
      this.userData.school.ref
        .collection("students-" + this.userData.school.currentYear)
        .where("STUDENT_GROUP_ID", "==", classGroup.STUDENT_GROUP_ID)
        .orderBy("firstName", "asc")
        .get()
        .then((docs) => {
          tmpStudents = [];
          var counter = 0;
          docs.docs.forEach((doc) => {
            var student = doc.data();
            student.id = doc.id;
            student.ref = doc.ref;
            counter++;
            console.log(
              counter,
              "ss",
              student.FIRST_NAME,
              student.STUDENT_GROUP_ID
            );
            if (!student.moved) {
              tmpStudents.push(student);
            }
          });

          axios
            .post(
              "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
              {
                url:
                  "https://hub.esis.edu.mn/svc/api/hub/group/student/list/" +
                  classGroup.STUDENT_GROUP_ID,
                token: this.tokenData.token,
              }
            )
            .then(async (res) => {
              var resData = res.data.RESULT;
              console.log(
                resData,
                "currentClassStudentNumber",
                tmpStudents.length,
                "newNumber",
                resData.length
              );
              if (res.status === 200 && resData) {
                var batch = fb.db.batch();

                this._removeNotExistingStudents(tmpStudents, resData, batch);
                this._isNewStudent(tmpStudents, resData);

                resData.forEach((item) => {
                  item["classGroup-" + this.userData.school.currentYear] =
                    classGroup.ref;
                  item["classGroupName-" + this.userData.school.currentYear] =
                    item.STUDENT_GROUP_NAME ? item.STUDENT_GROUP_NAME : null;
                  item.role = "student";
                  item.roleName = "Сурагч";
                  item.readfrom_esis = true;
                  item.email = item.EMAIL ? item.EMAIL : null;
                  item.readfrom_esis = true;
                  item.departmentIndex = parseInt(item.ACADEMIC_LEVEL);
                  item.classGroupName = item.STUDENT_GROUP_NAME;
                  item.deleted = false;
                  item.created = new Date();
                  item.lastName = item.LAST_NAME ? item.LAST_NAME : null;
                  item.firstName = item.FIRST_NAME ? item.FIRST_NAME : null;
                  item.currentlyExisting = true;

                  batch.set(
                    this.userData.school.ref
                      .collection(
                        "students-" + this.userData.school.currentYear
                      )
                      .doc(item.PERSON_ID.toString()),
                    item,
                    { merge: true }
                  );
                });

                batch.commit().then(() => {
                  if (resData.length > 0) {
                    // console.log("updating... ", prog.ref.path);
                    var halfYearDate = new Date(
                      new Date().getFullYear() + "-" + "12" + "-" + "31"
                    );

                    // var halfYearDate = new Date(
                    //   new Date().getFullYear() +
                    //     "-" +
                    //     this.$store.state.calendarButez2[2].months[0].name +
                    //     "-" +
                    //     this.$store.state.calendarButez2[2].months[0].days[0].day
                    // );
                    // var halfYear2Date = new Date(
                    //   new Date().getFullYear() +
                    //     "-" +
                    //     this.$store.state.calendarButez2[3].months[
                    //       this.$store.state.calendarButez2[3].months.length - 1
                    //     ].name +
                    //     "-" +
                    //     this.$store.state.calendarButez2[3].months[
                    //       this.$store.state.calendarButez2[3].months.length - 1
                    //     ].days[
                    //       this.$store.state.calendarButez2[3].months[
                    //         this.$store.state.calendarButez2[3].months.length - 1
                    //       ].days.length - 1
                    //     ].day
                    // );
                    var tmp = {
                      numberOfStudents: resData.length,
                      readFromESISAt: new Date(),
                    };
                    tmp[
                      "numberOfStudents-" +
                        this.$store.state.schoolCurrentSemester +
                        "-" +
                        this.userData.school.currentYear
                    ] = resData.filter(
                      (student) => new Date(student.ACTION_DATE) <= halfYearDate
                    ).length;

                    // tmp["numberOfStudents-2-" + this.userData.school.currentYear] =
                    //   res.data.result.filter(
                    //     (student) => new Date(student.ACTION_DATE) <= halfYear2Date
                    //   ).length;
                    classGroup.ref.update(tmp);
                    classGroup.loading = false;
                    if (warningDialog)
                      this.$swal.fire(
                        "Нийт " +
                          resData.length +
                          " сурагчийн мэдээлэл нэмэгдлээ"
                      );
                  } else {
                    if (warningDialog)
                      this.$swal.fire("Шинээр сурагч нэмэгдээгүй байна.");
                  }
                });
              } else {
                console.log("ERROR", classGroup.ref.path);
              }
            });
        });
    },
    _loadStudentsOfProg2(ktoken, warningDialog) {
      this.loading = true;
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
          {
            url: "https://hub.esis.edu.mn/svc/api/hub/student/list",
            token: ktoken,
          }
        )
        .then(async (res) => {
          console.log("ZZZ", res);
          if (res.status === 200) {
            var batch = fb.db.batch();
            res.data.RESULT.forEach((item) => {
              // item["classGroup-" + this.userData.school.currentYear] = prog.ref;
              item["classGroupName-" + this.userData.school.currentYear] =
                item.STUDENT_GROUP_NAME ? item.STUDENT_GROUP_NAME : null;
              item.role = "student";
              item.roleName = "Сурагч";
              item.readfrom_esis = true;
              item.email = item.EMAIL ? item.EMAIL : null;
              item.readfrom_esis = true;
              item.departmentIndex = parseInt(item.ACADEMIC_LEVEL);
              item.classGroupName = item.STUDENT_GROUP_NAME;
              item.deleted = false;
              item.created = new Date();
              item.lastName = item.LAST_NAME ? item.LAST_NAME : null;
              item.firstName = item.FIRST_NAME ? item.FIRST_NAME : null;
              var studentDocRef = this.userData.school.ref
                .collection(
                  "students-" + String(this.userData.school.currentYear)
                )
                .doc(item.PERSON_ID.toString());
              console.log(studentDocRef);
              batch.set(studentDocRef, item, { merge: true });
            });

            batch.commit().then(() => {
              if (res.data.RESULT.length > 0) {
                // var tmp = {
                //   numberOfStudents: res.data.result.length,
                //   readFromESISAt: new Date(),
                // };

                // prog.ref.update(tmp);
                this.loading = false;
                if (warningDialog)
                  this.$swal.fire(
                    "Нийт " +
                      res.data.RESULT.length +
                      " сурагчийн мэдээлэл нэмэгдлээ"
                  );
              } else {
                if (warningDialog)
                  this.$swal.fire("Шинээр сурагч нэмэгдээгүй байна.");
              }
            });
          }
        });
    },
    _loadSingleClass(prog) {
      if (this.$store.state.closeESIS_call) {
        this.$swal.fire(
          "Төгсгөлтэй холбоотойгоор хаасан. Шаардлагатай бол холбогдоорой!"
        );
      } else {
        this.$swal({
          title: "ESIS системээс сурагчдын мэдээллийг шинэчлэн татаx уу?",
          text: "Шинээр нэмэгдсэн бүлгийн мэдээллийг татаж, системд xадгална.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.tokenData) {
              this._loadStudentsOfProg(prog, true);
            }
          }
        });
      }
    },
    _loadClassGroupsFromESIS() {
      if (this.$store.state.closeESIS_call) {
        this.$swal.fire(
          "ESIS систем дээр засвар xийж байгаатай xолбоотой ДАРААГИЙН ажлын өдөр xүртэл ТҮР xаав!"
        );
      } else {
        if (
          this.userData.school._esisUserName &&
          this.userData.school._esisPword
        ) {
          this.$swal({
            title: "ESIS системээс бүлгийн мэдээллийг шинэчлэн татаx уу?",
            text: "Шинээр нэмэгдсэн бүлгийн мэдээллийг татаж, системд xадгална.",
            type: "warning",
            showCancelButton: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            confirmButtonText: "Тийм",
            cancelButtonText: "Үгүй",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.loading = true;
              if (this.userData.role == "superadmin") {
                console.log("/_schoolTokens/" + this.userData.school.id);
                console.log(this.tokenData.token);
              }
              if (this.tokenData.token) {
                axios
                  .post(
                    "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                    {
                      url: "https://hub.esis.edu.mn/svc/api/hub/group/list",
                      token: this.tokenData.token,
                    }
                  )
                  .then(async (res) => {
                    console.log(res, "res");
                    if (res.status === 200) {
                      this.loading = false;
                      var batch = fb.db.batch();
                      var counter = 0;
                      console.log(res.data);
                      res.data.RESULT.forEach(async (item) => {
                        // var studentCount = await this.userData.school.ref
                        //   .collection(
                        //     "students-" + this.userData.school.currentYear
                        //   )
                        //   .where("deleted", "==", false)
                        //   .where("ACADEMIC_LEVEL", "==", item.ACADEMIC_LEVEL)
                        //   .get()
                        //   .then((docs) => docs.size);
                        batch.set(
                          this.userData.school.ref
                            .collection(
                              "departments-" + this.userData.school.currentYear
                            )
                            .doc("department-" + item.ACADEMIC_LEVEL),
                          {
                            createdAt: new Date(),
                            deleted: false,
                            index: parseInt(item.ACADEMIC_LEVEL),
                            name: item.ACADEMIC_LEVEL,
                            // numberOfStudents: studentCount,
                            startYear: this.userData.school.currentYear,
                          },
                          { merge: true }
                        );
                        item.name = item.STUDENT_GROUP_NAME;
                        item.fullName = item.STUDENT_GROUP_NAME;
                        item.startYear = this.userData.school.currentYear;
                        item.departmentName = item.ACADEMIC_LEVEL;
                        item.createdAt = new Date();
                        item.deleted = false;
                        item.currentlyExisting = true;
                        //555
                        var foundClassGroup = this._findClassGroup(
                          item.STUDENT_GROUP_ID
                        );
                        if (
                          foundClassGroup &&
                          foundClassGroup._changedClassTeacherByManager
                        ) {
                          delete item.TEACHER_ID;
                          delete item.TEACHER_NAME;
                          console.log(
                            "!!!!!!",
                            item,
                            foundClassGroup.STUDENT_GROUP_ID,
                            foundClassGroup.STUDENT_GROUP_NAME
                          );
                        }
                        //start of saving class teacher info
                        var cRef = this.userData.school.ref
                          .collection(
                            "departments-" + this.userData.school.currentYear
                          )
                          .doc("department-" + item.ACADEMIC_LEVEL)
                          .collection("programs")
                          .doc(String(item.STUDENT_GROUP_ID));
                        var classInfo = {};
                        classInfo[
                          "myClassInfo-" + this.userData.school.currentYear
                        ] = fb.firestore.FieldValue.arrayUnion({
                          classGroupRef: cRef,
                          STUDENT_GROUP_NAME: item.STUDENT_GROUP_NAME,
                          ACADEMIC_YEAR: item.ACADEMIC_YEAR,
                        });

                        batch.set(
                          this.userData.school.ref
                            .collection("teachers")
                            .doc(String(item["TEACHER_ID"])),
                          classInfo,
                          { merge: true }
                        );
                        //end of saving class teacher info
                        batch.set(
                          this.userData.school.ref
                            .collection(
                              "departments-" + this.userData.school.currentYear
                            )
                            .doc("department-" + item.ACADEMIC_LEVEL)
                            .collection("programs")
                            .doc(item.STUDENT_GROUP_ID.toString()),
                          item,
                          { merge: true }
                        );
                        counter++;
                      });
                      setTimeout(() => {
                        console.log(counter);
                        batch.commit().then(async () => {
                          if (counter > 0) {
                            this.$swal.fire(
                              "Нийт " + counter + " бүлгийн мэдээлэл нэмэгдлээ!"
                            );
                            for (const dep of this.departments) {
                              for (const cg of dep.classGroups) {
                                // console.log(cg.STUDENT_GROUP_NAME, cg.currentlyExisting)
                                var foundCG = res.data.RESULT.find(
                                  (item) =>
                                    item.STUDENT_GROUP_ID == cg.STUDENT_GROUP_ID
                                );
                                if (!foundCG) {
                                  cg.ref.delete();
                                }
                              }
                            }
                          } else {
                            this.$swal.fire("Шинээр бүлэг нэмэгдээгүй байна!");
                          }
                          // this.userData.school.ref.update({
                          //   _es_groups_read: true,
                          // });
                          fb.db
                            .doc("/_schoolTokens/" + this.userData.school.id)
                            .update({
                              _es_groups_read: true,
                              _es_groups_readAt: new Date(),
                            });
                        });
                      }, 5000);
                    } else {
                      alert("Мэдээлэл татаxад алдаа гарлаа.");
                      this.fb.db
                        .collection("esis-connection-failed")
                        .doc(this.userData.school.id)
                        .set(
                          {
                            calledGroupsAt: new Date(),
                            accessLink:
                              "https://hub.esis.edu.mn/svc/api/hub/teacher/list",
                            school: this.userData.school,
                          },
                          { merge: true }
                        );
                    }
                  });
              }
            }
          });
        }
      }
    },
    _getJoinedName(item) {
      var llname = "";
      if (item.lastName2) {
        llname = item.lastName2 + ". " + item.firstName;
      } else if (item.lastName && item.lastName.length > 0) {
        llname = item.lastName[0];
        llname = llname + ". " + item.firstName;
      } else llname = item.firstName;
      return llname;
    },

    _addTeacher(prog) {
      this.selectedProgram = prog;
      this.selectedProgram.teacher = {
        ref: prog.teacherRef,
        name2: prog.teacherName,
      };

      this.selectTeacherDialog = true;
    },
    async _saveTeacherToClass() {
      var ttt = {};
      // var teacherRef = this.selectedProgram.teacher.ref;
      var dataa = {};
      if (
        (this.selectedProgram.teacher &&
          this.selectedProgram.teacher.value == "noteacher") ||
        this.selectedProgram.teacher.ref == undefined
      ) {
        dataa = {
          teacherRef: null,
          teacherName: null,
          className: this.selectedProgram.className
            ? this.selectedProgram.className
            : null,
        };
        ttt["myClassRef-" + this.userData.school.currentYear] = null;

        if (this.selectedProgram.teacherRef) {
          this.selectedProgram.teacherRef.update(ttt).then(() => {
            this.selectedProgram.ref.update(dataa).then(() => {
              this._closeDialog();
            });
          });
        } else {
          this.selectedProgram.ref.update(dataa).then(() => {
            this._closeDialog();
          });
        }
      } else {
        var xx = await this.selectedProgram.teacher.ref.get();
        dataa = {
          teacherRef: this.selectedProgram.teacher.ref,
          teacherName:
            xx.data().lastName && xx.data().lastName.length > 0
              ? xx.data().lastName[0].toUpperCase() + ". " + xx.data().firstName
              : "" + xx.data().firstName,
          className: this.selectedProgram.className
            ? this.selectedProgram.className
            : null,
        };
        ttt["myClassRef-" + this.userData.school.currentYear] =
          this.selectedProgram.ref;

        this.selectedProgram.ref.update(dataa).then(() => {
          if (dataa.teacherRef) {
            dataa.teacherRef.update(ttt).then(() => {
              this._closeDialog();
            });
          } else {
            // dataa.teacherRef.update(ttt).then(() => {
            //   this._closeDialog();
            // });
            this._closeDialog();
          }
        });
      }
    },
    _closeDialog() {
      this.selectTeacherDialog = false;

      this.selectedProgram = null;
      this.selectedTeacher = null;
    },
    _calStudents() {
      this.departments.forEach((dep) => {
        dep.classgroups.forEach(async (progr) => {
          var studentRef = await this.school.ref
            .collection("students")
            .where("programs", "==", progr.ref)
            .get();
          if (!studentRef.empty) {
            progr.ref.update({ numberOfStudents: studentRef.size });
          } else {
            console.log(studentRef.size);
          }
        });
      });
    },
    async _setupp() {
      fb.db
        .doc("/_schoolTokens/" + this.userData.school.id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            var diffInHours =
              (new Date() - doc.data().tokenTakenAt.toDate()) /
              (1000 * 60 * 60);

            if (diffInHours >= 24) {
              console.log("old");
              this._updateTokenFromESIS();
            } else {
              this.tokenData = doc.data();
              this.tokenData.ref = doc.ref;
              this.tokenData.id = doc.id;
              console.log("token currently OK", diffInHours);
              console.log(this.tokenData.token);
            }
            if (this.userData.role == "superadmin") {
              console.log("TokEN  from fb !!!!!", doc.ref.path);
              console.log(doc.data().schoolName);
            }
          } else {
            console.log("try to login");
          }
        });

      if (this.userData.role == "superadmin") this.editable = true;

      if (this.userData.school) {
        if (this.userData.role == "teacher") this.editable = false;
        this.school = this.userData.school;
        this.selectedYear = this.school.currentYear;
        this._getDepartments(this.userData.school.currentYear);
      } else {
        this.school = null;
        this.selectedYear = null;
      }

      if (this.userData.ppschool != null) {
        fb.db
          .collection(this.userData.ppschool + "/teachers")
          .where("role", "==", "teacher")
          .where("deleted", "==", false)
          .orderBy("firstName", "asc")
          .get()
          .then((docs) => {
            this.teachers = [];
            this.teachers = [
              { name2: "<< Багшгүй >>", ref: null, value: "noteacher" },
            ];
            docs.docs.forEach((doc) => {
              let teacher = doc.data();
              teacher.id = doc.id;
              teacher.ref = doc.ref;
              teacher.name2 = this._getJoinedName(teacher);
              if (!teacher.moved) this.teachers.push(teacher);
            });
          });
      }
    },
    _getDepartments(startYear) {
      this.userData.school.ref
        .collection("departments-" + startYear)
        .orderBy("index", "desc")
        .onSnapshot((querySnapshot) => {
          var counter = 1;
          this.departments = [];
          this.classGroups = [];
          this.numberOfAllGroups = 0;
          querySnapshot.forEach((doc) => {
            this.countOfPrograms = 0;
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.classGroups = null;
            dep.ref
              .collection("programs")
              .where("deleted", "==", false)
              .orderBy("name", "asc")
              .onSnapshot((querySnapshot) => {
                dep.classGroups = [];
                dep.numberOfStudents = 0;
                querySnapshot.forEach(async (doc) => {
                  // doc.data() is never undefined for query doc snapshots
                  let prog = doc.data();
                  prog.id = doc.id;
                  prog.ref = doc.ref;
                  if (prog.fullName == undefined) {
                    prog.ref.update({ fullName: dep.name + prog.name });
                  }
                  if (
                    prog.numberOfStudents != undefined &&
                    prog.numberOfStudents != null
                  )
                    dep.numberOfStudents =
                      dep.numberOfStudents + prog.numberOfStudents;
                  prog.index = counter++;
                  this.numberOfAllGroups++;
                  prog.loading = false;
                  dep.classGroups.push(prog);
                  // this.departments.classGroups.push(dep.classGroups);
                  // this.countOfPrograms = this.countOfPrograms + 1;
                });
              });
            this.departments.push(dep);
          });
        });
    },
    _getStudentNumber(dep) {
      var count = 0;
      if (dep.classGroups) {
        for (const cg of dep.classGroups) {
          if (
            cg[
              "numberOfStudents-" +
                this.$store.state.schoolCurrentSemester +
                "-" +
                this.userData.school.currentYear
            ]
          ) {
            count =
              count +
              cg[
                "numberOfStudents-" +
                  this.$store.state.schoolCurrentSemester +
                  "-" +
                  this.userData.school.currentYear
              ];
          }
        }
      }
      return count;
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    filteredTeachers() {
      if (this.teachers) {
        var list = this.teachers.map((teacher, index) => {
          if (teacher["FIRST_NAME"])
            teacher.name2 = index + 1 + ". " + teacher["FIRST_NAME"];
          return teacher;
        });
        return list;
      } else return [];
    },
    countStudents() {
      var count = 0;
      if (this.departments) {
        this.departments.forEach((dep) => {
          count = count + this._getStudentNumber(dep);
        });
      }
      return count;
    },
  },
  created() {
    var halfYear2Date = console.log(halfYear2Date);
    if (this.userData.school) this._setupp();
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: #2196f3;
  color: white;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}
</style>
